import React, { useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import TextInput from "src/components/atoms/text-input";
import PanelLayout from "src/components/layouts/panel-layout";
import ProfileTabs from "src/components/molecules/profile-tabs";
import BiographyViewComponent from "src/components/organisms/biography/biography-view/biography-view.component";
import useCustomTabs from "src/hooks/useCustomTabs";
import {
  useGetUserAnalyticsMutation,
  useGetUserMutation,
} from "src/services/users";
import EditEmail from "./elements/edit-email";
import useModal from "src/hooks/useModal";
import { useAppSelector } from "src/store/hook";
import { otherUserSelector } from "src/store/features/user/selectors";
import EditBioBtn from "src/components/molecules/edit-bio-btn";
import ShareButton from "src/components/molecules/share-button";
import { getUserProfileStatus } from "src/helpers";
import PublishDropdown from "./elements/publish-dropdown";
import UserAnalytics from "./elements/user-analytics";
import SelectInput from "src/components/atoms/select-input";
import EditKeywords from "./elements/edit-keywords";
import { SHARE_TYPE } from "src/components/molecules/share-button/types";
import Posts from "./elements/user-posts/posts";
import { useGetAllTopicsMutation } from "src/services/topics";
import { splitKeywords } from "src/components/molecules/crop-modal-easy/utils";
import { usePostsContext } from "src/context/posts-context";
import { POST_TYPES } from "src/types";
import Label from "src/components/atoms/label";
import ContentLeaderToggle from "src/components/molecules/content-leader-toggle";
import EditMembership from "./elements/edit-membership";

const ViewUser = () => {
  const [searchParams] = useSearchParams();

  const { handleTabsChange, activeTab } = useCustomTabs(
    "user-tabs",
    Number(searchParams.get("activeTab")),
  );

  const otherUser = useAppSelector(otherUserSelector);

  const { id } = useParams();

  const { toggle, isOpen } = useModal();
  const { toggle: toggleEditKeywords, isOpen: isOpenEditKeywords } = useModal();
  const { toggle: toggleEditMembership, isOpen: isOpenEditMembership } = useModal();

  const [getUser] = useGetUserMutation<any>();
  const [getUserAnalytics] = useGetUserAnalyticsMutation();
  const [getAllTopics] = useGetAllTopicsMutation();

  const { fetchDataMyPosts, setPageMyPosts, setFilter, setMyPosts } =
    usePostsContext();

  useEffect(() => {
    getUser({ id });
    getAllTopics();
  }, []);

  useMemo(() => {
    if (activeTab == 3) {
      getUserAnalytics({ id });
    }
  }, [activeTab]);

  useEffect(() => {
    setFilter(POST_TYPES.ALL);
    setMyPosts([]);
    setPageMyPosts(2);
    fetchDataMyPosts({ page: 1, filter: POST_TYPES.ALL, userId: id });

    console.log("fetching posts");
    return () => {
      setMyPosts([]);
    };
  }, []);

  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      <div className="rounded-xl rounded-b-none bg-gray-400">
        <div className="sticky top-0 z-30 flex flex-col items-start gap-3 rounded-t-xl  border-b border-border-color bg-[#191c24] xl:flex-row xl:items-center">
          <div className="order-2 xl:order-1">
            {/* tabs */}
            <ProfileTabs
              handleTabsChange={handleTabsChange}
              activeTab={activeTab}
            />
          </div>
          {activeTab !== 3 && (
            <div className="md-mx-0 order-1 mx-16 mt-12 flex flex-wrap items-center gap-3 xl:order-2 xl:ml-auto xl:mt-0 xl:gap-6">
              {activeTab == 1 && (
                <div className="order-2 xl:order-1">
                  <EditBioBtn
                    to={`/admin/users/${id}/edit-bio`}
                    title="Edit biography"
                  />{" "}
                </div>
              )}

              {getUserProfileStatus(
                otherUser?.is_publish,
                otherUser?.admin_publish_hidden,
              ) == "1" && activeTab == 1 ? (
                <div className="order-3 xl:order-2">
                  <ShareButton
                    description={`Check out ${otherUser?.name}'s biography on our platform Putiton-E!`}
                    shareType={SHARE_TYPE.PROFILE}
                    slug={otherUser?.slug}
                    image={otherUser?.share_img}
                    username={otherUser?.name}
                  />
                </div>
              ) : (
                ""
              )}

              {![3, 2].includes(activeTab) && (
                <div className="order-1 xl:order-3">
                  <PublishDropdown />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="">
          {activeTab === 0 && (
            <>
              <div className="flex flex-row gap-4">
                <div className="w-full">
                  <div className="flex flex-col gap-4 p-24">
                    <div className="w-full sm:max-w-[513px]">
                      <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                        <TextInput
                          name="email"
                          type="email"
                          label="Email"
                          value={otherUser?.email}
                          disabled={true}
                          containerClassName="w-full"
                          required={true}
                        />
                        <button
                          className="text-16 font-medium leading-24 text-white-para sm:pb-12 sm:pt-38"
                          onClick={toggle}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="w-full sm:max-w-[513px]">
                      <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                        <SelectInput
                          label="Keywords"
                          name="keywords"
                          options={[]}
                          containerClassName="tags-input w-full tags-input-scroll-hidden"
                          mode="tags"
                          required={false}
                          placeholder=""
                          value={splitKeywords(otherUser?.keywords)}
                          disabled={true}
                        />
                        <button
                          className="text-16 font-medium leading-24 text-white-para sm:pb-12 sm:pt-38"
                          onClick={toggleEditKeywords}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="w-full sm:max-w-[513px]">
                      <div>
                        <Label name="Content Leader" id={""}></Label>
                        <ContentLeaderToggle user={otherUser} />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="flex flex-col gap-4 p-24 w-full">
                  <div className="w-full sm:max-w-[513px]">
                    <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                      <SelectInput
                        label="Membership type"
                        name="membership"
                        options={[
                          { value: "free", label: "Free" },
                          { value: "basic", label: "Basic" },
                        ]}
                        value={otherUser?.membership}
                      />
                      <button
                        className="text-16 font-medium leading-24 text-white-para sm:pb-12 sm:pt-38"
                        onClick={toggleEditMembership}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <EditEmail
                toggle={toggle}
                isOpen={isOpen}
                email={otherUser?.email || ""}
              />
              <EditKeywords
                toggle={toggleEditKeywords}
                isOpen={isOpenEditKeywords}
              />
              <EditMembership
                toggle={toggleEditMembership}
                isOpen={isOpenEditMembership}
              />
            </>
          )}
          {activeTab === 1 && (
            <BiographyViewComponent
              user={otherUser}
              searchParams={{ type: "" }}
              contentsClassName="top-[73px]"
            />
          )}
          {activeTab === 2 && <Posts />}

          {activeTab === 3 && <UserAnalytics />}
        </div>
      </div>
    </PanelLayout>
  );
};

export default ViewUser;
